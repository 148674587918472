// ----------------------------------------------------------------------

import { _id } from 'src/_mock/assets';

const MOCK_ID = _id[1];

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  HEALTHCHECK: '/healthcheck',
};

// ----------------------------------------------------------------------

export const paths = {
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  // HEALTHCHECK
  healthcheck: ROOTS.HEALTHCHECK,
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,

    reservation: {
      root: `${ROOTS.DASHBOARD}/reservation`,
    },
    room: {
      root: `${ROOTS.DASHBOARD}/room`,
    },
    statistics: {
      root: `${ROOTS.DASHBOARD}/statistics`,
      dashboard: `${ROOTS.DASHBOARD}/statistics`,
      reservation: {
        list: `${ROOTS.DASHBOARD}/statistics/reservation/list`,
      },
      sales: {
        list: `${ROOTS.DASHBOARD}/statistics/sales/list`,
      },
    },
    accommodation: {
      root: `${ROOTS.DASHBOARD}/accommodation`,
    },

    member: {
      root: `${ROOTS.DASHBOARD}/member/list`,
      new: `${ROOTS.DASHBOARD}/member/new`,
      list: `${ROOTS.DASHBOARD}/member/list`,
      account: `${ROOTS.DASHBOARD}/member/account`,
      edit: (id: number) => `${ROOTS.DASHBOARD}/member/${id}/edit`,
    },
  },
};
